<template>
  <div>
      <table width="100%" class="vs-table vs-table--tbody-table ">
          <tr>
              <td class="td vs-table--td" style="padding: 5px">
                  Customer/Ship-to Code
              </td>
              <td class="td vs-table--td" style="padding: 5px">
                  {{ ': ' + this.data.customer_code}}
              </td>
          </tr>
          <tr>
              <td class="td vs-table--td" style="padding: 5px">
                  SO/Invoice Code
              </td>
              <td class="td vs-table--td" style="padding: 5px">
                  {{ ': ' + this.data.so_code}}
              </td>
          </tr>
          <tr>
              <td class="td vs-table--td" style="padding: 5px">
                  Sales Return Data
              </td>
              <td class="td vs-table--td" style="padding: 5px">
                  {{ ': ' + this.data.sales_return_data}}
              </td>
          </tr>
          <tr>
              <td class="td vs-table--td" style="padding: 5px">
                 Receive Date
              </td>
              <td class="td vs-table--td" style="padding: 5px">
                  {{ ': ' + formatDate(this.data.receive_date)}}
              </td>
          </tr>
          <tr>
              <td class="td vs-table--td" style="padding: 5px">
                 Note
              </td>
              <td class="td vs-table--td" style="padding: 5px">
                  {{ ': ' + this.data.note}}
              </td>
          </tr>
      </table>

      <table width="100%" class="vs-table vs-table--tbody-table print">
          <thead class="vs-table--thead">
              <tr>
                <th width="10%">SKU Code</th>
                <th width="15%">SKU Description</th>
                <th width="10%">HU</th>
                <th width="10%">Qty Receive</th>
                <th width="10%">ED</th>
                <th width="10%">Batch</th>
                <th width="10%">Checked Status</th>
                <th width="10%">Qty</th>
                <th width="15%">Suggested Warehouse Area</th>
              </tr>
          </thead>
          <tbody>
              <tr v-for="(item, index) in items" :key="index" style="padding-bottom;: 5px">
                  <td class="td vs-table--td" style="padding: 5px">
                    {{ item.item_code }}
                  </td>
                  <td class="td vs-table--td" style="padding: 5px">
                    {{ item.item_name }}
                  </td>
                  <td class="td vs-table--td" style="padding: 5px">
                    {{ item.item_unit }}
                  </td>
                  <td class="td vs-table--td" style="padding: 5px">
                    {{ item.qty }}
                  </td>
                  <td class="td vs-table--td" style="padding: 5px">
                    {{ formatDate(item.expired_date) }}
                  </td>
                  <td class="td vs-table--td" style="padding: 5px">
                    {{ item.batch }}
                  </td>
                  <td class="td vs-table--td" style="padding: 5px">
                    {{ item.checked_status }}
                  </td>
                  <td class="td vs-table--td" style="padding: 5px">
                    {{ item.qty_putaway }}
                  </td>
                  <td class="td vs-table--td" style="padding: 5px">
                    {{ item.wh_name }}
                  </td>
              </tr>
          </tbody>
      </table>
      <div class="flex justify-center w-full pt-6 print-button">
            <div
            style="
                position: absolute;
                left: 50%;
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
            ">
                <vs-button class="mr-2 mb-3 mt-12" color="danger" @click="handleBack()">Close</vs-button>
                <vs-button class="mr-2 mb-3 mt-12" color="primary" @click="handlePrint()">Print</vs-button>
            </div>
        </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  components: {
  },
  mounted() {
      this.id = this.$route.params.id;
      this.mode = this.$route.params.mode;
      this.initData();
  },
  data() {
      return {
          dialog: {
              save: false,
          },
          id: null,
          attachment: null,
          data: {
                id: null,
                customer_code: null,
                so_code: null,
                code: null,
                gir_number: null,
                sales_return_data: null,
                receive_date: null,
                note: null,
                file: null,
                status: null,
                // fileUrl: '',
              },
            items: [{
                item_code: '',
                item_name: '',
                item_unit: '',
                qty: '',
                batch: '',
                expired_date: '',
                warehouse_area: '',
                checked_status: '',
            }],
            statusReadonly: false,
      }
  },
  methods: {
      async handlePrint() {
          await window.print();
      },
      initData() {
          this.$http
              .get(this.$store.state.inbound.customerReturn.baseUrlPath + "/putaway/" + this.id)
              .then((resp) => {
                  console.log(resp)
                  if (resp.code == 200) {
                        var now = moment().toDate();
                        this.data.id = resp.data.sr_id
                        this.data.customer_code = resp.data.customer_code
                        this.data.so_code = resp.data.so_code
                        this.data.so_id = resp.data.so_id
                        this.data.sales_return_data = resp.data.code + " " + resp.data.gir_number
                        this.data.code = resp.data.code
                        this.data.gir_number = resp.data.gir_number
                        this.data.receive_date = resp.data.receive_date == null ? now : resp.data.receive_date
                        this.data.note = resp.data.note
                        this.data.status = resp.data.status
                        this.items = resp.data.lines
                        
                  } else {
                  }
              });
      },
      handleBack() {
        this.$router.push({
            name: this.$store.state.inbound.customerReturn.baseRouterName,
        });
    },
  },
  computed: {
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('DD MMM YYYY');
            };
        },
    },
}
</script>
<style>
/* Set the page size to A4 and the orientation to landscape */
@page {
  size: A4 landscape;
}

/* Set the font size to 12px for printing */
@media print {
  body {
      font-size: 12px;
      background: none !important;
  }

  * {
      background: none !important;
  }

  header,
  footer,
  .print-button,
  #content-area * :not(.content-wrapper *) {
      display: none !important;
      visibility: hidden !important;
  }

  .router-view,
  .router-content {
      padding: 0 !important;
      margin: 0 !important;
  }
}

table.print {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
}

table.print td,
table.print th {
  border: 1px solid #abab !important;
  text-align: left;
  padding: 8px;
}
</style>
